import { PayloadAction } from "@reduxjs/toolkit";
import { select, call, put } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  deleteItemFailed,
  deleteItemSucceeded,
} from "../manualStockTakingSlice";

import { getCurrentStockTakeId } from "./getCurrentStockTakeId";

export function* deleteItemSaga(
  serviceContainer: ServiceContainer,
  action: PayloadAction<string>
): Saga {
  const { stockTakingService, handleSagaError } = serviceContainer;
  const itemId = action.payload;

  if (typeof itemId !== "string") throw new Error("Missing params.itemId");

  try {
    const stockId: number = yield select(selectWarehouseId);

    const stockTakeId: string = yield call(
      getCurrentStockTakeId,
      serviceContainer
    );

    yield call(
      [stockTakingService, "deleteItem"],
      stockId,
      stockTakeId,
      itemId
    );

    yield put(deleteItemSucceeded(action.payload));
  } catch (e) {
    yield* handleSagaError(
      (errorCode) => `saga:stock-taking:deleteItemSaga:${errorCode}`,
      e,
      put(deleteItemFailed(toErrorActionPayload(e)))
    );
  }
}
