import { Selector } from "../../types";

import { StockTakenSubmissionStatus } from "./stockTakenSubmissionSlice";
import { doesStockTakeHaveProblem } from "./util/doesStockTakeHaveProblem";

export const selectStockTakenSubmissionState: Selector<
  StockTakenSubmissionStatus
> = (state) => state.stockTakenSubmission.status;

export const selectHasStockTakeSubmissionWithProblem: Selector<boolean> = (
  state
) =>
  state.stockTakenSubmission.status.type === "loaded" &&
  state.stockTakenSubmission.status.submissions.some((x) =>
    doesStockTakeHaveProblem(x)
  );
