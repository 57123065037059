import { UpdateItemStockTakingQuantityRequest } from "@ahlsell-group/store20-stock-taking-service";
import { PayloadAction } from "@reduxjs/toolkit";
import { actions } from "redux-router5";
import { put, select, call } from "redux-saga/effects";

import { ServiceContainer } from "../../../app/serviceContainer";
import { Saga } from "../../../types";
import toErrorActionPayload from "../../../util/toErrorActionPayload";
import { navigateBack } from "../../routing/navigateBack";
import routes from "../../routing/routes";
import { selectWarehouseId } from "../../warehouse/warehouseSelectors";
import {
  UpdateItemPayload,
  updateItemFailed,
  updateItemSucceeded,
} from "../manualStockTakingSlice";

import { getCurrentStockTakeId } from "./getCurrentStockTakeId";

export function* updateItemStockTakingQuantitySaga(
  serviceContainer: ServiceContainer,
  action: PayloadAction<UpdateItemPayload>
): Saga {
  const { stockTakingService, handleSagaError } = serviceContainer;

  const { item, continueScanning } = action.payload;
  const { itemId, stockTakingQuantity, comment } = item;

  if (typeof itemId !== "string") throw new Error("Missing params.itemId");

  try {
    const stockId: number = yield select(selectWarehouseId);

    const stockTakeId: string = yield call(
      getCurrentStockTakeId,
      serviceContainer
    );

    if (stockTakingQuantity === undefined) {
      throw new Error("StockTakingQuantity is undefined");
    }

    const request: UpdateItemStockTakingQuantityRequest = {
      stockId,
      stockTakeId,
      itemId,
      quantity: stockTakingQuantity,
      comment,
    };

    yield call([stockTakingService, "updateItemStockTakingQuantity"], request);

    yield put(updateItemSucceeded(item));
    yield call(navigateBack, 2);
    if (continueScanning) {
      yield put(
        actions.navigateTo(
          routes.stockTaking.manual.route,
          { goto: "scanner" },
          { replace: true }
        )
      );
    }
  } catch (e) {
    yield* handleSagaError(
      (errorCode) =>
        `saga:stock-taking:updateItemStockTakingQuantitySaga:${errorCode}`,
      e,
      put(updateItemFailed(toErrorActionPayload(e)))
    );
    yield call(navigateBack);
  }
}
