import { useTranslation } from "@ahlsell-group/app-localization";
import { AceChip } from "@ahlsell-group/app-ui/AceChip";
import { StockTake } from "@ahlsell-group/store20-stock-taking-service";
import { Typography } from "@ahlsell-group/ui-kit/data-display";
import classNames from "classnames";
import React from "react";

import { getDate, getTime, isToday } from "../util/date";
import { doesStockTakeHaveProblem } from "../util/doesStockTakeHaveProblem";

interface StockTakeSubmissionListItemProps {
  submission: StockTake;
  highlight: boolean;
}

const StockTakeSubmissionListItem: React.FC<StockTakeSubmissionListItemProps> =
  React.forwardRef<HTMLDivElement, StockTakeSubmissionListItemProps>(
    ({ submission, highlight }, ref) => {
      const { t } = useTranslation("common");
      const { stockTakeId, submittedDateTimeUtc, vivaldiId, items } =
        submission;

      const submissionDiff = items.some((x) => x.quantityDiff);
      const submissionProblem = doesStockTakeHaveProblem(submission);

      const today = submittedDateTimeUtc && isToday(submittedDateTimeUtc);

      return (
        <div
          ref={ref}
          className={classNames(
            "bg-theme-secondary-bg-50 px-3 mb-2 rounded relative",
            {
              "border-l-8 border-l-theme-status-text-error": submissionProblem,
              "border-l-8 border-l-theme-secondary-notification-attention":
                !submissionProblem && submissionDiff,
            }
          )}
          data-cy={`StockTakeSubmissionListItem-${stockTakeId} ${
            submissionProblem ? "StockTakeSubmissionListItem-problem" : ""
          }`}
        >
          {today && (
            <AceChip
              color="success"
              wiggle={highlight}
              className="absolute -right-1 -top-2 mt-px"
            >
              {t("stockTakingReview.new")}
            </AceChip>
          )}
          <div className="flex items-center py-1">
            <Typography
              variant="body"
              component="span"
              className="grow"
              data-cy="StockTakenSubmission-submission-id"
            >
              #{vivaldiId}
            </Typography>
            <div className="flex flex-col text-right">
              <Typography variant="body-sm" className="flex-1" component="span">
                {submittedDateTimeUtc && getTime(submittedDateTimeUtc)}
              </Typography>
              <Typography
                variant="body-sm"
                component="span"
                color="gray"
                className="flex-1"
              >
                {submittedDateTimeUtc &&
                  (today ? t("today") : getDate(submittedDateTimeUtc))}
              </Typography>
            </div>
          </div>
        </div>
      );
    }
  );

export default StockTakeSubmissionListItem;
